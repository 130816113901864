// Components that need to be available in the Rails templates
import { defineAsyncComponent } from "vue";

const ConfirmationInstructionsForm = defineAsyncComponent(() => import("~/components/navigation/confirmation_instructions_form.vue"));
const EditPasswordForm = defineAsyncComponent(() => import("~/components/navigation/edit_password_form.vue"));
const ExpandableSideMenu = defineAsyncComponent(() => import("~/components/navigation/expandable_side_menu.vue"));
const LoginForm = defineAsyncComponent(() => import("~/components/navigation/login_form.vue"));
const NavSelector = defineAsyncComponent(() => import("~/components/navigation/nav_selector.vue"));
const NewUserForm = defineAsyncComponent(() => import("~/components/navigation/new_user_form.vue"));
const ResetFilterChip = defineAsyncComponent(() => import("~/components/navigation/reset_filter_chip.vue"));
const ResetPasswordForm = defineAsyncComponent(() => import("~/components/navigation/reset_password_form.vue"));
const StocksShow = defineAsyncComponent(() => import("~/components/stocks/show.vue"));
const StocksOverview = defineAsyncComponent(() => import("~/components/stocks/overview.vue"));
const ToastComponent = defineAsyncComponent(() => import("~/components/navigation/toast_component.vue"));
const UnlockUserForm = defineAsyncComponent(() => import("~/components/navigation/unlock_user_form.vue"));
const UsersIndex = defineAsyncComponent(() => import("~/components/users/index.vue"));
const UserAccount = defineAsyncComponent(() => import("~/components/users/account.vue"));

export default {
    ConfirmationInstructionsForm,
    EditPasswordForm,
    ExpandableSideMenu,
    LoginForm,
    NavSelector,
    NewUserForm,
    ResetFilterChip,
    ResetPasswordForm,
    StocksShow,
    StocksOverview,
    ToastComponent,
    UnlockUserForm,
    UsersIndex,
    UserAccount,
}
